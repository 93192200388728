import React from "react";
import Section from "../Section";
import Slider from "../slider/Slider";

const Home = () => {
  return (
    <>
      <div>
        <Slider />
      </div>
      <Section />
    </>
  );
};

export default Home;
