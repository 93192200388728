import React from 'react'
import './Nakliye.css'
const Nakliye = () => {
  return (
    <>

    </>
  )
}

export default Nakliye