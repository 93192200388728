import React from "react";
import { useTranslation } from "react-i18next";
import Video from "../assets/videos/1.mp4"

const Gallery = () => {
  const { t } = useTranslation();
  const media = [];
  for (let i = 1; i <= 20; i++) {
    const imageUrl = require(`../assets/images/${i}.jpeg`);
    const videoUrl = require(`../assets/videos/1.mp4`);
    media.push({ image: imageUrl, video: videoUrl });
  }
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">{t("nav.gallery")}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {media.map((mediaItem, index) => (
          <div key={index} className="relative overflow-hidden group">
            {mediaItem.image ? (
              <img
                src={mediaItem.image}
                alt={`Image ${index + 1}`}
                className="w-full h-auto transform transition-transform group-hover:scale-105"
              />
            ) : (
              // <video
              //   src={mediaItem.video}
              //   autoPlay
              //   loop
              //   muted
              //   className="w-full h-auto transform transition-transform group-hover:scale-105"
              // />
              <video
                // src={mediaItem.video.default}
                src={Video}
                autoPlay
                loop
                muted
                playsInline
                className="w-full h-auto transform transition-transform group-hover:scale-105"
              />
            )}
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-50 transition-opacity">
              <p className="text-white text-center absolute inset-x-0 bottom-4">
                {/* Media {index + 1} */}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-semibold mb-4">Gallery</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//         {images.map((imageUrl, index) => (
//           <div key={index} className="relative overflow-hidden group">
//             <img
//               src={imageUrl}
//               alt={`Image ${index + 1}`}
//               className="w-full h-auto transform transition-transform group-hover:scale-105"
//             />
//             <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-50 transition-opacity">
//               <p className="text-white text-center absolute inset-x-0 bottom-4">
//                 {/* Image {index + 1} */}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export default Gallery;
