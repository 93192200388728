import "./SliderData.css"

export const sliderData = [
  {
    image:
      "https://www.grainscanada.gc.ca/en/grain-quality/sampling-grain/guide-taking-representative-sample/_images/fig-20-en.png",
    //   heading: "Shoes Villa",
    //   desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image:
      "https://www.grainscanada.gc.ca/en/grain-quality/sampling-grain/guide-taking-representative-sample/_images/fig-5-en.png",
    //   heading: "Women Fashion",
    //   desc: "Up to 30% off on all onsale proucts.",
  },
  {

    image: require("../../assets/images/4.jpeg"),
  },
  {

    image: require("../../assets/images/10.jpeg"),
  },
  {

    image: require("../../assets/images/12.jpeg"),
  },
  
  // // electronics
  {
    image:
      "https://www.solidworks.com/sites/default/files/2017-12/PRODUCTS-ELECTRICAL-PROFESSIONAL-schematic-mechanic-iot-hero-002.jpg",
    //   heading: "Awesome ",
    //   desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image:
      "https://www.mtu.edu/gradschool/programs/degrees/electrical-engineering/images/electrical-engineering-banner1600.jpg",
    //   heading: "Awesome ",
    //   desc: "Up to 30% off on all onsale proucts.",
  },
  // // transportation
  {
    image:
      "https://image-prod.iol.co.za/16x9/800/RFA-CEO-Gavin-Kelly-said-the-road-freight-and-logistics-industry-was-being-held-to-ransom-and-attacked-by-parties-who-are-unhappy-with-conditions-or-matters-within-the-sector?source=https://xlibris.public.prod.oc.inl.infomaker.io:8443/opencontent/objects/6f972bc2-a9a8-57fe-b3f2-b7e8875bcf29&operation=CROP&offset=0x62&resize=979x550",
    //   heading: "Awesome ",
    //   desc: "Up to 30% off on all onsale proucts.",
  },
  // {

  //   image: require("../../assets/images/1.jpeg"),
  // }
];
